import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useRequest, useBoolean } from 'ahooks';
import Icon from '@onedesign/icon';
import { useGroupContext } from 'providers/GroupProvider';
import { getRecords } from 'services/breakout';
import { Loading, Box } from '@oneboard/ui-components';
import { StyledBreakoutRoomIntro, StyledWrap, StyledRecordList } from './BreakoutRoomIntro.style';

const GroupRecord = ({ onClose }) => {
  const { meetingId } = useParams();

  const getAllRecords = () => getRecords({ courseId: meetingId });

  const { data: allRecords, error, loading } = useRequest(getAllRecords);

  return (
    <StyledWrap>
      <div className="head">
        <div className="title">先前分組結果</div>
        <div className="close" onClick={onClose}>
          <Icon name='ChevronLeftOutline' size="xs" />
        </div>
      </div>
      <div className="content">
        {loading && (
          <Box display="flex" alignItems="center" justifyContent="center" height="100%" >
            <Loading />
          </Box>
        )}
        {error && (
          <Box display="flex" alignItems="center" justifyContent="center" height="100%" >
            資料錯誤
          </Box>
        )}
        {allRecords && allRecords.data.map(record => {
          return (
            <RecordList recordData={record} key={record.timestamp} />
          );
        })}
      </div>
    </StyledWrap>
  );
};

GroupRecord.propTypes = {
  onClose: PropTypes.func
};

const RecordList = ({ recordData }) => {
  const { startTime, groupAmount } = recordData;
  const {
    breakoutRoomIntroActions,
    breakoutRoomResultActions,
    openBreakoutRoomResult,
    resultReviewActions
  } = useGroupContext();

  const listClickHandler = () => {
    openBreakoutRoomResult(recordData);
    breakoutRoomIntroActions.setFalse();
    breakoutRoomResultActions.setTrue();
    resultReviewActions.setTrue();
  };

  return (
    <StyledRecordList onClick={listClickHandler}>
      <div className="icon record">
        <Icon name='FileAltSolid' color='#377DFF' />
      </div>
      <div className="optionContent">
        <div className="title">{startTime}</div>
        <div className="desc">分組數量：{groupAmount}</div>
      </div>
    </StyledRecordList>
  );
};

RecordList.propTypes = {
  recordData: PropTypes.object
};

export const BreakoutRoomIntro = ({ className, onClose }) => {
  const {
    toggleBreakoutRoomSidebar,
    breakoutRoomIntroActions
  } = useGroupContext();
  const [introState, introStateActions] = useBoolean(true);
  const [groupRecordState, groupRecordStateActions] = useBoolean(false);

  const breakoutRoomOptionEnterHandler = () => {
    breakoutRoomIntroActions.setFalse();
    toggleBreakoutRoomSidebar();
  };

  const recordOptionEnterHandler = () => {
    introStateActions.setFalse();
    groupRecordStateActions.setTrue();
  };

  const recordOptionBackHandler = () => {
    groupRecordStateActions.setFalse();
    introStateActions.setTrue();
  };

  return (
    <StyledBreakoutRoomIntro className={className} data-testid="BreakoutRoomIntro">
      {introState && <StyledWrap>
        <div className="head">
          <div className="title">分組活動</div>
          <div className="close" onClick={onClose}>
            <Icon name='ChevronLeftOutline' size="xs" />
          </div>
        </div>
        <div className="content">
          <div className="optionList" onClick={breakoutRoomOptionEnterHandler}>
            <div className="icon blue">
              <Icon name='PlusSolid' color="#fff" />
            </div>
            <div className="optionContent">
              <div className="title">開始分組活動</div>
              <div className="desc">開始一個新的分組討論活動</div>
            </div>
          </div>
          <div className="optionList" onClick={recordOptionEnterHandler}>
            <div className="icon green">
              <Icon name='HistorySolid' color="#fff" />
            </div>
            <div className="optionContent">
              <div className="title">先前分組結果</div>
              <div className="desc">查看先前分組結果紀錄</div>
            </div>
          </div>
        </div>
      </StyledWrap>}
      {groupRecordState && <GroupRecord onClose={recordOptionBackHandler} />}
    </StyledBreakoutRoomIntro>
  );
};

BreakoutRoomIntro.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func
};