import styled from 'styled-components';

export const StyledDeviceSetupPage = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 0 200px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  .spinner {
    color: #fff;
    animation: spin 1s linear infinite;
    @keyframes spin {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
  }

  > .version {
    position: absolute;
    right: 10px;
    bottom: 10px;
    color: #555;
  }
`;

export const DeviceSelectForm = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 72px;
  max-width: 65rem;
  height: auto;
  > * {
    flex-basis: auto;
  }
  @media (min-width: 900px) {
    flex-direction: row;
    /* padding: 2.5rem 0 6rem 0; */
    > * {
      flex-basis: 50%;
    }
    @media (max-height: 800px) {
      /* padding: 2rem 0 2rem; */
    }
  }
`;

export const StyledSettingGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 900px) {
    padding: 2rem 0 2rem 0;
    border-right: unset;
  }
`;

export const StyledPreviewGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  @media (max-width: 900px) {
    padding: 0;
  }
`;

export const StyledWaitingPage = styled.div`
  max-width: 740px;
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  @media (max-height: 768px) {
    max-width: 420px;
  }

  > .wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding-top: 15px;

    > .video {
      position: relative;
      max-width: 700px;
      width: 100%;
      height: auto;
      display: flex;
      justify-content: center;
      align-items: center;

      .previewVideo {
        overflow: hidden;
        border-radius: 32px;
        width: 100%;
        font-size: 0;
        border-radius: 32px;
        @media (max-width: 768px) {
          video {
            object-fit: contain;
            max-height: 360px;
          }
        }
      }
    }

    > .setting {
      max-width: 700px;
      width: 100%;
      display: flex;
      justify-content: flex-start;
    }

    .settingBtn {
      margin-top: 18px;
      padding: 0;
      display: flex;
      align-items: center;
      color: #fff;
      background-color: transparent;
      color: #637381;
      border: none;
      cursor: pointer;

      > span {
        margin-left: 8px;
      }
      &:hover {
        color: #000;
      }
    }

    > .control {
      width: 100%;
      padding-bottom: 24px;
      margin-top: 18px;
      display: flex;
      justify-content: center;

      .primaryBtn {
        background-color: #ff7257;
        width: 100%;
      }
    }
  }
`;

export const RedirectButtonContainer = styled.div`
  .primaryBtn {
    background-color: #ff7257;
    width: 100%;
  }
`;

export const StyledCheckDevicePage = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;

  > .pic {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    text-align: center;
    flex: 1 1 auto;
  }

  > .desc {
    margin-top: 64px;
    color: #637381;
    text-align: center;
    font-size: 20px;
    line-height: 30px;
  }

  > .tip {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex: 1 1 auto;
    padding: 24px 0;
    font-size: 14px;
    line-height: 24px;
    text-align: center;

    .downloadLink {
      color: #a1a4b1;
      > span {
        color: #fff;
      }
      &:hover {
        color: #fff;
      }
    }
  }
`;

export const StyledMicroTest = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
`;
