import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import {
  useLocalVideo,
  useRosterState,
  useAudioVideo,
  useContentShareState,
  ContentShare,
  useRemoteVideoTileState,
} from 'amazon-chime-sdk-component-library-react';
import { useQuery } from 'utils/hooks/useQuery';
import { useMeetingDispatch, useMeetingState, MeetingStates, MeetingRoles } from '@oneboard/meeting';
import {
  TeachingMaterialContainer,
  VideoListCarouselPanel,
  WorldWallModal,
  QuizModalV2,
  SyncVideoListV2,
  StudentInfoBox,
  PrivateMessageList,
} from 'containers';
import MainLayout from 'layouts/MainLayoutV2';
import { NetworkNotification, InsertPictureModal, SyncMainBlock } from 'components';
import { Roles, NOTIFICATION_TYPE_I18N, NOTIFICATION_TYPE_KEY } from 'constants/index';
import { useGroupContext } from 'providers/GroupProvider';
import { useVideoListContext } from 'providers/VideoListProvider';
import { useNotification } from 'utils/hooks/useNotification';
import { useViewMode } from 'utils/hooks/useViewMode';
import { useIframeBridge } from 'utils/hooks/useIframeBridge';
import { useInteractiveObjects } from 'utils/hooks/useInteractiveObjects';
import { usePrevious, useRequest, useSessionStorageState } from 'ahooks';
import { complementarySet } from 'utils/array';
import { Box, Modal, FullButton } from '@oneboard/ui-components';
import PreviewController from '@netless/preview-controller';
import { useWhiteboard } from '@oneboard/whiteboard';
import { useBreakoutMeeting } from 'providers/BreakoutMeetingProvider';
import { useNetworkContext } from 'providers/NetworkProvider';
import { LoadingOutlined } from '@ant-design/icons';
import { MAX_STUDENT_VIDEO_COUNT } from 'config';
import { useQuizService } from 'utils/hooks/useQuiz';
import { useImage } from 'utils/hooks/useImage';
import { useRealtimeActions } from 'utils/hooks/useRealtimeActions';
import { useStudentInfoContext } from 'providers/StudentInfoProvider';
import { useRoom } from 'providers/RoomProvider';
import {
  StyledSyncMultiple,
  NotificationLayout,
  ContentShareContainer,
  StyledBroadcastModal,
  StyledSyncVideoList,
} from './SyncMultiple.style';
import { t } from 'utils/i18n';
import styled from 'styled-components';
import Whiteboard from 'components/NewOneBoard/Whiteboard';
import ToolBox from 'components/NewOneBoard/ToolBox';
import Teacher from 'components/NewOneBoard/VideoInfo/Teacher';
import Footer from 'components/NewOneBoard/Footer';
import AdvisorContainer from 'components/NewOneBoard/VideoInfo/common/AdvisorContainer';
import AdvisorVideo from 'components/NewOneBoard/VideoInfo/common/WhiteboardVideo';
import ToolModal from 'components/NewOneBoard/common/ToolModal';
import { CancelButton, SendButton } from 'components/NewOneBoard/common/MessageButton';
import { Pagination } from 'antd';
import { v4 as uuid } from 'uuid';
import { message } from 'antd';

const MainContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const BottomContainer = styled.div`
  display: flex;
`;

const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const ButtonTextContainer = styled.div`
  display: flex;
  height: 68px;
  justify-content: space-between;
  gap: 12px;
  width: 100%;
  align-items: center;
`;

const ButtonContainer = styled(Box)`
  display: flex;
  gap: 10px;
`;

const Hint = styled.p`
  color: #637381;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.375rem;
  margin: 0;
  user-select: none;
`;

const PictureContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
`;

export const SyncMultiple = () => {
  const { meetingId } = useParams();
  const query = useQuery();
  const audioVideo = useAudioVideo();
  const meetingState = useMeetingState();
  const {
    stagedAttendeeIds,
    role,
    roomId,
    attendeeId: selfAttendeeId,
    toolboxAuthAttendeeIds,
    courseType,
    raisedHandAttendeeIds,
    chatDisabledAttendeeIds,
  } = meetingState.context;
  const {
    onstageFullModalState,
    toggleStageFullModal,
    carouselPanelState,
    toggleCarouselPanel,
    usersMuteHandler,
    removeUsersMute,
    worldWallModalState,
    toggleWorldWallModal,
    BreakoutRoomState,
    breakoutRoomStep,
    resultReviewState,
    broadcastModalState,
    setBroadcastModalActions,
  } = useGroupContext();
  const { videoList: localVideoList } = useVideoListContext();
  const {
    unstageAttendee,
    unMuteAttendee,
    muteAttendee,
    updateVideoList,
    updateStaged,
    enableToolbox,
    disableChat,
  } = useMeetingDispatch();
  const { isBreakoutRoom, broadcastMessageToAll } =
    useBreakoutMeeting();
  const { openNotification } = useNotification();
  const { roster } = useRosterState();
  const { receiveImage, updateImageStatus } = useImage();
  const [receivedImages, setReceivedImages] = useState([]);
  const attendees = Object.values(roster);
  const students = attendees.filter((attendee) => attendee.role === Roles.Student);
  const isAdvisorJoined = attendees.find((attendee) => attendee.role === Roles.Advisor);
  const studentsPrevious = usePrevious(students) || [];
  const { service: whiteboardService } = useWhiteboard();
  const { networkNotice, closeNetworkNotice, openNetworkNotice, networkStatus } = useNetworkContext();
  const { userId: studentInfoId, studentInfoState, toggleStudentInfo } = useStudentInfoContext();
  const { receiveRaiseHandAction, receiveLowerHandAction, receiveAppFinishInit } = useRealtimeActions();
  const { insertPictureModalSwitch } = useRoom();
  const mainRef = useRef(null);
  const advisors = Object.values(roster).filter((attendee) => attendee.role === Roles.Advisor);
  const { attendeeIdToTileId } = useRemoteVideoTileState();
  const [isSettingModalOpen, setIsSettingModalOpen] = useState(false);
  const [iframeGroup, setIframeGroup] = useState([]);
  const allAttendees = useMemo(() => Object.values(roster), [roster]);
  useViewMode();
  useIframeBridge();
  useInteractiveObjects();
  const raisedHandPrevious = usePrevious(raisedHandAttendeeIds) || [];
  const [currentPage, setCurrentPage] = useState(1);
  const [openReviewImagesModal, setOpenReviewImagesModal] = useState(false);
  const pageSize = 1;

  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  const currentImage = receivedImages[currentPage - 1];

  const handleReceivedImage = useCallback((dataMessage) => {
    if (dataMessage) {
      const imageData = dataMessage.json();
      setReceivedImages((prevImages) => [...prevImages, imageData]);
      message.info('學生已上傳圖片');
    }
  }, []);

  const closeTeachingMaterialHandler = (material) => {
    switch (material.type) {
      case 'iframe':
        setIframeGroup((prev) => prev.filter((item) => item.sources !== material.sources));
        break;
      default:
        break;
    }
  };

  const { isVideoEnabled, toggleVideo } = useLocalVideo();
  const [muteDefault, setMuteDefault] = useState(true);
  const isJoined = meetingState.matches(MeetingStates.Joined);

  useEffect(() => {
    if (!isVideoEnabled && query.role !== Roles.Advisor) {
      toggleVideo();
    }
  }, [isJoined]);

  const notificationStudentNumberChangeHandler = () => {
    const complementarySetResult = complementarySet(students, studentsPrevious);
    if (students.length > studentsPrevious.length) {
      complementarySetResult.map((student) =>
        openNotification({
          name: student.name,
          type: t(NOTIFICATION_TYPE_I18N.ENTER),
          key: NOTIFICATION_TYPE_KEY.ENTER,
        })
      );
    } else {
      complementarySetResult.map((student) =>
        openNotification({
          name: student.name,
          type: t(NOTIFICATION_TYPE_I18N.LEAVE),
          key: NOTIFICATION_TYPE_KEY.LEAVE,
        })
      );
    }
  };

  const notificationRaisedHandHandler = () => {
    if (raisedHandAttendeeIds.length === 0) return;
    if (raisedHandAttendeeIds.length < raisedHandPrevious.length) return;

    const complementarySetResult = complementarySet(raisedHandAttendeeIds, raisedHandPrevious);

    complementarySetResult.map((raisedHandAttendeeId) =>
      openNotification({
        name: roster[raisedHandAttendeeId]?.name,
        type: t(NOTIFICATION_TYPE_I18N.RAISED_HAND),
      })
    );
  };

  useEffect(() => {
    notificationRaisedHandHandler();
  }, [raisedHandAttendeeIds.length]);

  const [isShowPreviewMenu, setIsShowPreviewMenu] = useState(false);

  const pagePreviewHandler = (state) => {
    setIsShowPreviewMenu(state);
  };

  useEffect(() => {
    notificationStudentNumberChangeHandler();
  }, [students.length]);

  useEffect(() => {
    if (!audioVideo) {
      return;
    }
    const rosterUpdateCallback = (chimeAttendeeId, present) => {
      if (!present) {
        removeUsersMute(chimeAttendeeId);
        const isOnStaged = stagedAttendeeIds.includes(chimeAttendeeId);
        if (isOnStaged) unstageAttendee({ attendeeId: chimeAttendeeId });

        return;
      }
      if (isBreakoutRoom) return;
      muteDefault ? muteAttendee({ attendeeId: chimeAttendeeId }) : unMuteAttendee({ attendeeId: chimeAttendeeId });
      updateStaged({ attendeeId: chimeAttendeeId, stagedAttendeeIds });
      usersMuteHandler({
        [chimeAttendeeId]: muteDefault,
      });

      const isEnableToolbox = toolboxAuthAttendeeIds.includes(chimeAttendeeId);
      if (isEnableToolbox) {
        enableToolbox({ attendeeId: chimeAttendeeId });
      }
    };

    audioVideo.realtimeSubscribeToAttendeeIdPresence(rosterUpdateCallback);

    return () => {
      audioVideo.realtimeUnsubscribeToAttendeeIdPresence(rosterUpdateCallback);
    };
  }, [audioVideo, muteDefault, stagedAttendeeIds, toolboxAuthAttendeeIds]);

  const { sharingAttendeeId } = useContentShareState();
  const contentSharingAttendeeId = useMemo(() => sharingAttendeeId?.split('#')[0] || '', [sharingAttendeeId]);
  const isShowContentShare = useMemo(
    () => contentSharingAttendeeId && contentSharingAttendeeId !== selfAttendeeId,
    [selfAttendeeId, contentSharingAttendeeId]
  );

  const isSelfContentShare = useMemo(
    () => contentSharingAttendeeId && contentSharingAttendeeId === selfAttendeeId,
    [selfAttendeeId, contentSharingAttendeeId]
  );

  const [broadcastValue, setBroadcastValue] = useState('');

  const broadcastValueChangeHandler = (e) => {
    const textAreaValue = e.target.value.trim();
    if (textAreaValue.length > 280) return;

    setBroadcastValue(textAreaValue);
  };

  const broadcastModalCloseHandler = () => {
    setBroadcastModalActions.setFalse();
  };

  const { loading: broadcastModalLoading, run: broadcastMessageRun } = useRequest(
    () => broadcastMessageToAll({ courseId: meetingId, message: broadcastValue }),
    {
      manual: true,
    }
  );

  const broadcastModalSubmitHandler = async () => {
    await broadcastMessageRun();
    setBroadcastValue('');
    setBroadcastModalActions.setFalse();
  };

  const { teacherQuizStateMatches, States } = useQuizService();
  const isOpenQuizModal = [States.Setting, States.Starting, States.End, States.Reviewing].some(teacherQuizStateMatches);

  const [stagedData, setStagedData] = useSessionStorageState('stagedData', {
    defaultValue: [],
  });

  useEffect(() => {
    setStagedData(stagedAttendeeIds);
  }, [stagedAttendeeIds]);

  useEffect(() => {
    if (stagedData.length !== 0) {
      updateStaged({
        attendeeId: selfAttendeeId,
        stagedAttendeeIds: stagedData,
      });
    }
  }, []);

  const isTilePaused = meetingState.matches({
    [MeetingStates.Joined]: `${MeetingRoles.Teacher}.${MeetingStates.TileState}.${MeetingStates.Paused}`,
  });

  useEffect(() => {
    if (isTilePaused) {
      openNetworkNotice();
    }
  }, [isTilePaused]);

  useEffect(() => {
    if (!audioVideo) return;

    receiveRaiseHandAction();
    receiveLowerHandAction();
    receiveAppFinishInit();
  }, [audioVideo, receiveRaiseHandAction, receiveLowerHandAction, receiveAppFinishInit]);

  /**
   * 老師自己的輪播列表
   */
  const nextLocalVideoList = useMemo(() => {
    return localVideoList.filter((_tile, index) => index < MAX_STUDENT_VIDEO_COUNT);
  }, [localVideoList]);

  /**
   * 顧問在的時候在 broadcast 列表資訊
   */
  useEffect(() => {
    if (!nextLocalVideoList || !isAdvisorJoined) return;
    updateVideoList({ videoList: nextLocalVideoList });
  }, [isAdvisorJoined, nextLocalVideoList]);

  // 被禁言的學生重新加入保持禁言
  useEffect(() => {
    allAttendees.forEach((attendee) => {
      if (chatDisabledAttendeeIds.includes(attendee.chimeAttendeeId)) {
        disableChat({ attendeeId: attendee.chimeAttendeeId });
      }
    });
  }, [allAttendees.length]);

  useEffect(() => {
    receiveImage(handleReceivedImage);
  }, [receiveImage, handleReceivedImage]);

  const approveImage = async (imageFileName, senderAttendeeId) => {
    updateImageStatus(imageFileName, senderAttendeeId, 'agree');
    setCurrentPage(1);
    setReceivedImages((prevImages) => prevImages.filter((image) => image.fileName !== imageFileName));
    const currentImageUrl = `https://upload.oneclass.com.tw/${roomId}/upload/${imageFileName}`;
    try {
      const img = new Image();
      img.onload = () => {
        const id = uuid();
        const imageInformation = {
          uuid: id,
          centerX: 0,
          centerY: 0,
          width: img.width,
          height: img.height,
          locked: false,
        };

        whiteboardService.insertImage(imageInformation);
        whiteboardService.completeImageUpload(imageInformation.uuid, img.src);
      };
      img.src = currentImageUrl;
      setOpenReviewImagesModal(false);
      return { success: true, currentImageUrl };
    } catch (error) {
      console.error('Upload error:', error);
      return { success: false };
    }
  };

  const rejectImage = (imageFileName, senderAttendeeId) => {
    setCurrentPage(1);
    updateImageStatus(imageFileName, senderAttendeeId, 'disagree');
    setReceivedImages((prevImages) => prevImages.filter((image) => image.fileName !== imageFileName));
    setOpenReviewImagesModal(false);
  };

  const handleReceivedImagesModal = () => {
    setOpenReviewImagesModal(!openReviewImagesModal);
  };

  return (
    <Box display='flex' flexDirection='column' height='100%'>
      {networkNotice && (
        <NotificationLayout>
          <NetworkNotification networkStatus={networkStatus} onClose={closeNetworkNotice} />
        </NotificationLayout>
      )}
      <PreviewController
        room={whiteboardService}
        isVisible={isShowPreviewMenu}
        handlePreviewState={pagePreviewHandler}
      />

      <StyledSyncMultiple
        isNotification={breakoutRoomStep !== BreakoutRoomState.setting || resultReviewState || networkNotice}
      >
        <MainLayout
          main={
            <MainContainer>
              <SyncMainBlock ref={mainRef}>
                <PrivateMessageList filterRole={Roles.Teacher} containerRef={mainRef} />
                <Whiteboard
                  toolBox={<ToolBox meetingId={meetingId} />}
                  userRole={Roles.Teacher}
                  receivedImages={receivedImages}
                  handleReceivedImagesModal={handleReceivedImagesModal}
                />
                {nextLocalVideoList.length > 0 && (
                  <StyledSyncVideoList>
                    <SyncVideoListV2 videoList={nextLocalVideoList} courseType={courseType} />
                  </StyledSyncVideoList>
                )}
                {isShowContentShare && (
                  <ContentShareContainer>
                    <ContentShare />
                  </ContentShareContainer>
                )}

                {isSelfContentShare && (
                  <Box width='100%' height='100%' position='absolute' zIndex='1000'>
                    <ContentShare />
                  </Box>
                )}
                <TeachingMaterialContainer iframeGroup={iframeGroup} onClose={closeTeachingMaterialHandler} />
                {worldWallModalState && role === Roles.Teacher && <WorldWallModal onClose={toggleWorldWallModal} />}
                {carouselPanelState && <VideoListCarouselPanel onClose={toggleCarouselPanel} />}
                {onstageFullModalState && (
                  <Modal
                    onClose={toggleStageFullModal}
                    header={t('mainPage.teacher.syncMultiple.warningNotice', '警告通知')}
                  >
                    {t('mainPage.teacher.syncMultiple.stageFulled', '人數已滿無法再邀請人上台')}
                  </Modal>
                )}
                {whiteboardService && studentInfoState && (
                  <StudentInfoBox id={studentInfoId} onClose={toggleStudentInfo} room={whiteboardService} />
                )}

              </SyncMainBlock>
              <AdvisorContainer isStudentList={nextLocalVideoList.length > 0}>
                {advisors &&
                  advisors.map((advisor) => (
                    <AdvisorVideo
                      key={advisor.chimeAttendeeId}
                      userType={advisor.role}
                      tileId={attendeeIdToTileId[advisor.chimeAttendeeId]}
                      attendeeId={advisor.chimeAttendeeId}
                      advisorName={advisor.name}
                    />
                  ))}
              </AdvisorContainer>
            </MainContainer>
          }
          side={
            <RightContainer>
              <Teacher
                isSettingModalOpen={isSettingModalOpen}
                muteDefault={muteDefault}
                setMuteDefault={setMuteDefault}
              />
            </RightContainer>
          }
          footer={
            <BottomContainer>
              <Footer
                isSettingModalOpen={isSettingModalOpen}
                setIsSettingModalOpen={setIsSettingModalOpen}
                courseType={courseType}
              />
            </BottomContainer>
          }
        />

        {broadcastModalState && (
          <Modal width='600px' header={t('mainPage.teacher.syncMultiple.broadcastMessage', '廣播訊息')}>
            <StyledBroadcastModal>
              <div className='content'>
                <textarea
                  placeholder={t(
                    'mainPage.teacher.syncMultiple.enterBroadcastMessage',
                    '在這裡輸入廣播訊息，傳送給所有成員。'
                  )}
                  maxLength={280}
                  onChange={broadcastValueChangeHandler}
                ></textarea>
                <div className='tip'>{broadcastValue.length}/280</div>
              </div>
            </StyledBroadcastModal>
            <Box display='flex' width='100%' pt={8}>
              <Box width='100%' mr={2}>
                <FullButton.Secondly
                  onClick={broadcastModalCloseHandler}
                  disabled={broadcastModalLoading ? true : false}
                >
                  {t('mainPage.teacher.syncMultiple.cancel', '取消')}
                </FullButton.Secondly>
              </Box>
              <Box width='100%' ml={2}>
                <FullButton onClick={broadcastModalSubmitHandler} disabled={broadcastValue.length === 0 ? true : false}>
                  {broadcastModalLoading ? (
                    <LoadingOutlined />
                  ) : (
                    t('mainPage.teacher.syncMultiple.sendMessage', '發送訊息')
                  )}
                </FullButton>
              </Box>
            </Box>
          </Modal>
        )}

        {openReviewImagesModal && (
          <ToolModal
            active={receivedImages?.length > 0}
            onClose={handleReceivedImagesModal}
            title='審核圖片'
            height='500px'
            justifyContent='flex-start'
            footerHeight='69px'
            footerContent={
              <ButtonTextContainer>
                <Hint>提醒您：通過審核的圖片將會顯示於白板畫面中</Hint>
                <ButtonContainer>
                  <CancelButton
                    className='ghost'
                    onClick={() => rejectImage(currentImage.fileName, currentImage.senderAttendeeId)}
                  >
                    未通過
                  </CancelButton>
                  <SendButton onClick={() => approveImage(currentImage.fileName, currentImage.senderAttendeeId)}>
                    通過
                  </SendButton>
                </ButtonContainer>
              </ButtonTextContainer>
            }
          >
            <PictureContainer>
              {currentImage && (
                <img
                  src={`https://upload.oneclass.com.tw/${roomId}/upload/${currentImage.fileName}`}
                  alt={currentImage.name}
                  style={{
                    width: 'fit-content',
                    height: '315px',
                    objectFit: 'contain',
                    borderRadius: '10px',
                    userSelect: 'none',
                  }}
                />
              )}
              <Pagination
                size='small'
                current={currentPage}
                onChange={onPageChange}
                pageSize={pageSize}
                total={receivedImages?.length}
              />
            </PictureContainer>
          </ToolModal>
        )}

        {insertPictureModalSwitch && <InsertPictureModal />}

        {isOpenQuizModal && <QuizModalV2 role={Roles.Teacher} />}
      </StyledSyncMultiple>
    </Box>
  );
};
